// ========================================================================= //
//    #CORE - BREAKPOINTS
// ========================================================================= //
$mobile: 320px;
$large-mobile: 400px;
$tablet: 600px;
$large-tablet: 800px;
$desktop: 1000px;
$large-desktop: 1600px;

@include add-breakpoint('mobile', $mobile);
@include add-breakpoint('large-mobile', $large-mobile);
@include add-breakpoint('tablet', $tablet);
@include add-breakpoint('large-tablet', $large-tablet);
@include add-breakpoint('desktop', $desktop);
@include add-breakpoint('large-desktop', $large-desktop);
$breakpoint-has-widths: ('mobile', 'large-mobile', 'tablet', 'large-tablet', 'desktop', 'large-desktop');

$spacing-unit: 1.5rem;
$spacing-unit--l: $spacing-unit * 2;
$spacing-unit--xl: $spacing-unit * 4;
$mobileMargin: $spacing-unit;
