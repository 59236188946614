// ========================================================================= //
// WRAPPER
// ========================================================================= //

@mixin container($size) {
  max-width: $size;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  @include container(1280px);
  padding-right: $spacing-unit--l;
  padding-left: $spacing-unit--l;
}
