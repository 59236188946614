// ========================================================================= //
//    #CORE/HTML - PAGE
// ========================================================================= //

* {
  box-sizing: border-box;
  word-wrap: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Remove default tappable touch color in iOS
}

:root {
  position: relative;
}

html {
  font-size: 10px;
  height: 100%;
  min-height: 100vh;
  background-color: lighten($black, 10%);
}

body {
  @include font-smoothing;
  background-color: $white;
  color: lighten($black, 20%);
  font-family: $systemFont;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -.85px;
  line-height: 1.45;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  word-spacing: .55px;
  height: 100%;
  transition: font-size .2s ease-out;

  background-size: cover;
  background-position: center;
  background-image: url(../images/bench-dark.jpg), linear-gradient(#0e121d 0%, #7fa2ff 50%, transparent);
  background-blend-mode: multiply;

  @include breakpoint($desktop) {
    background-attachment: fixed;
  }

  .fonts-loaded & {
    font-family: $bodyFont;
    font-weight: 300;
    letter-spacing: unset;
    word-spacing: unset;
  }

  @include breakpoint($large-tablet) {
    font-size: 1.6rem;
  }

  @media print {
    background-color: #fff;
    height: 100%;
    width: 100%;
  }

  &.body-grid {
    display: grid;
    // grid-template-rows: 50vh 1fr auto;
    grid-template-rows: 100vh 1fr auto;

    @include breakpoint($large-tablet) {

    }
  }
}

.main {
  background-color: #fff;
  background-image: linear-gradient($white, #c7d2e4);
  padding: 10vh 0;
  z-index: 1;
}
