.global-footer {
  background-color: $black;
  background-image: radial-gradient(circle at 50% 0%, lighten($black, 10%), $black);
  z-index: 1;
  font-family: $brandFont;
  text-transform: lowercase;

  @media print {
    display: none;
  }

  .list-inline {
    display: flex;
    justify-content: flex-end;

    li {
      margin: 2rem;
    }

    a {
      color: $white;
      // text-transform: uppercase;
    }
  }
}
