.global-header {
  color: #c7d2e4;

  .header-grid {
    display: grid;
    height: 100%;
    padding: 5vmin;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    place-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    width: 100vw;

    // &:not(.home) {

    // }

    @include breakpoint($desktop) {
    }
  }

  .brand {
    grid-area: 1/1/1/3;
  }

  .title-area {
    grid-area: 3/2/3/4;
    align-self: flex-end;
    text-align: right;

    .fonts-loaded & h3 {
      text-transform: capitalize;
    }
  }

  @media print {
    background: unset;
    color: unset;
    height: unset;
    box-shadow: unset;
    margin-bottom: 2rem;
  }

  ul {
    padding: 0;
  }

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.5rem;
      padding-right: 1.5rem;

      &:after {
        content: '|';
        position: absolute;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }

    a {
      color: currentColor;
    }
  }

}

@media print {
  .main {
    display: flex;
    flex-flow: column;
  }
}



.name__container {
  --letter: 15vh;
  grid-column-end: 3;
  grid-row-end: 3;
  margin: auto;
  text-align: center;
  width: calc(var(--letter) * 2.5);

  @media screen and (min-width: 600px) {
      width: calc(var(--letter) * 3);
  }

  @media screen and (min-width: 1100px) {
      width: calc(var(--letter) * 4);
  }
}


.name {
  display: block;
  // font-family: 'Major Mono Display', monospace;
  font-size: calc(var(--letter) - 0.1px);
  line-height: 0.75;
  overflow-wrap: break-word;
  position: relative;
  text-shadow: 1px 1px 0px #090a0b;

  .fonts-loaded & {
    font-family: $brandFont;
  }

  &:before {
    background-color: rgba(29, 35, 57, 0.4);
    // content: '';
    height: calc(100% + 0.25em);
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    z-index: -1;
  }
}
