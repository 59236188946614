// ========================================================================= //
//    #CORE/HTML - IMAGES
// ========================================================================= //

img {
  border: 0;
  display: block;
  font-style: italic;
  height: auto;
  vertical-align: middle;
  width: 100%;

  &.lazy {
    opacity: 0;
    transition: opacity 3s ease-in;
    will-change: opacity;

    &.lazyloaded {
      opacity: 1;
    }
  }
}
