.resume-grid {
  @include breakpoint($tablet) {
    display: grid;
    gap: 10vh;
    grid-template-columns: 1fr 4fr;
  }
}

.experience {
  list-style: none;
  margin: 0;
  padding-left: 0;

  li:not(:last-child) {
    margin-bottom: 5rem;
  }

  .details {}
  .description {}
}

.skills {
  ul {
    padding-left: 2rem;
    margin: 0;
  }

  .column {
    margin-bottom: 3rem;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-rows: 1fr 3fr;
      margin-bottom: 0;
    }
  }

  @media print {
    display: flex;
    justify-content: space-evenly;

    .column {
      flex: 1;
      margin: 0 1.5rem;
    }
  }
}

.brag {
  font-weight: 600;
}

.position {
  margin-bottom: 0;
}

.section {
  margin-bottom: 6rem;

  @media print {
    margin-bottom: 3vw;
    width: 100%;
  }

  &__description {
    margin-bottom: 6rem;

    @include breakpoint($tablet) {
      margin-bottom: 0;
    }
  }

  &__title h2 {
    margin-bottom: 1em;
    padding-bottom: 1ch;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: .3ch;
      width: 3ch;
      background-color: currentColor;
    }
  }
}

.brand {
  h1,
  h2 {
    text-transform: uppercase;
    line-height: 1;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-size: 2.5rem;
  }

  @media print {
    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    &.animate > * {
      animation: none;
      opacity: 1;
      transform: none;
    }
  }
}

.details {
  p:not(.time) {
    margin-bottom: 0;
  }
}

.contact {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    @include breakpoint($tablet) {
      justify-content: flex-start;
    }
  }

  li {
    @include breakpoint($tablet) {
      margin-right: 1.5rem;
    }
  }
}

#contact {
  @media print {
    display: none;
  }
}

.reveal {
  &.animate {
    opacity: 0;
    transform: translateY(2rem) scale(0.99);
    transform-origin: left top;
    will-change: opacity, transform;
    transition: .6s ease-out .25s;
    transition-property: opacity, transform;
  }

  &.animated {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

