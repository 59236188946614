// ========================================================================= //
//    #UTILITY - COLORS
// ========================================================================= //

@function palette($palette, $tone: 'base') {
  @return map-get(map-get(map-get($palettes, colors), $palette), $tone);
}

$palettes: (
  colors: (
    black: (
      base: #0E121D
    ),
    white: (
      base: #E6E9EE,
    ),
    brown: (
      light: #faf5eb,
      base: #978D83,
      dark: rgba(#020100, .12)
    ),
    red: (
      base: #bb1616
    )
  )
);

$white:   palette(white);
$black:   palette(black);
$red:   palette(red);

// Usage:
//
// Ex. Light brown
// p {
//   color: palette(brown, light);
// }
