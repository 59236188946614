// ========================================================================= //
//    #CORE/HTML - HEADING ELEMENTS
// ========================================================================= //

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 .5em;
  line-height: 1.414;
  font-weight: 700;

  .fonts-loaded & {
    // font-family: $headingFont;
    font-family: $brandFont;
    text-transform: lowercase;
  }
}

h1 {
  margin-top: 0;
  font-size: 2.441em;
}

h2 {
  font-size: 1.953em;

  @media print {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.563em;

  @media print {
    font-size: 1.8rem;
  }
}

h4 {font-size: 1.25em;}
