// ========================================================================= //
//    #CORE/HTML - TYPOGRAPHY
// ========================================================================= //

p {
  margin-top: 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;

  .fonts-loaded & {
    font-weight: 600;
  }
}

blockquote {}

cite {}

address {
  font-style: normal;
}
