// ========================================================================= //
// LINKS
// ========================================================================= //

a {
  color: $red;
  font-weight: 600;
  text-decoration: none;
  transition: color .15s ease-in-out;

  &:visited {
    color: darken($red, 10%);
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
    text-decoration-skip: ink;
    color: #B7372C;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .6;
  }

  &.link--external {
    padding-right: .5em;
    position: relative;

    &:after {
      content: '^';
      font-size: 0.8em;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(45deg);
    }
  }

}
