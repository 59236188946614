.portfolio {
  &-grid {
    display: grid;
    grid-gap: 10vh;
  }

  &__item {
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 6rem $spacing-unit--l;
      align-items: center;
    }
  }

  &__image {
    position: relative;
    height: 0;
    padding-top: 62.5%;
    overflow: hidden;
    margin: 0;

    @include breakpoint($tablet) {
      order: 1;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  // &__logo {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 2;
  //   fill: #fff;
  //   display: grid;
  //   place-content: center;
  //   transition: opacity 0.2s ease-out;

  //   svg {
  //     height: 75%;
  //     margin: auto;
  //     width: 75%;
  //   }
  // }

}
